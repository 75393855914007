<template>
  <div>
    <!-- 操作按钮 -->
    <div class="add-btn">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="
          dialogVisible = true;
          dialogTitle = '新增岗位';
        "
        >新增岗位</el-button
      >
    </div>
    <!-- 列表 -->
    <el-table :data="tabelData" stripe style="width: 100%" size="medium" v-loading="tabelLoading">
      <el-table-column label="序号" width="120" fixed align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="岗位名称" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="280">
        <template slot-scope="{ row }">
          <div class="btn-grounp">
            <span class="view-details" @click="handleResponseList(row.id)">修改责任清单</span>
            <span class="view-details" @click="handleEdit(row)">修改</span>
            <span class="danger-btn" @click="handelDelete(row.id)">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页-->
    <el-pagination
      :total="total"
      :current-page="tabelListParmas.pageNo"
      :page-size="tabelListParmas.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="resetForm"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" inline>
        <el-form-item label="岗位名称" prop="name">
          <el-input v-model.trim="ruleForm.name" placeholder="请输入岗位名称" style="width:364px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改责任清单dialog -->
    <add-dialog :dialog-response-visible.sync="dialogResponseVisible" :post-id="postId"></add-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { getItem } from '@/utils/storage.js';
import { getList, addOrEditConfig, deleteConfig } from 'api/super-admin/sys-setting/performance-post';
import addDialog from './addDialog.vue';
export default {
  components: {
    addDialog
  },
  data() {
    return {
      tabelLoading: false,
      dialogVisible: false,
      btnLoading: false,
      dialogResponseVisible: false,
      dialogTitle: '新增岗位',
      tabelData: [],
      tabelListParmas: {
        tenantId: getItem('orgId'),
        pageSize: 10,
        pageNo: 1
      },
      total: 0,
      //新增岗位dialog
      ruleForm: {
        id: '',
        name: '',
        tenantId: getItem('orgId')
      },
      rules: {
        name: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' },
          { min: 0, max: 20, message: '最大字符为20', trigger: 'blur' }
        ]
      },
      //修改责任清单dialog
      postId: '' //岗位id
    };
  },
  methods: {
    handleResponseList(id) {
      this.postId = id;
      this.dialogResponseVisible = true;
    },
    //删除
    handelDelete(id) {
      //删除
      this.$confirm('请确认是否删除该岗位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteConfig(id);
      });
    },
    //删除接口
    deleteConfig(id) {
      deleteConfig({ id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.getList();
        }
      });
    },
    //新增或者编辑接口
    addOrEditConfig() {
      this.btnLoading = true;
      addOrEditConfig(this.ruleForm)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.dialogVisible = false;
            this.$message.success('新增成功');
            this.getList();
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    handleEdit(row) {
      this.ruleForm = cloneDeep(row);
      this.ruleForm.tenantId = getItem('orgId');
      this.dialogTitle = '修改岗位';
      this.dialogVisible = true;
    },
    //确认操作
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addOrEditConfig();
        }
      });
    },
    //关闭dialog
    resetForm() {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
    },
    //分页接口
    getList() {
      this.tabelLoading = true;
      getList(this.tabelListParmas).then(res => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tabelLoading = false;
          this.total = total;
          this.tabelData = records;
        }
      });
    },
    handleSizeChange(size) {
      this.tabelListParmas.pageNo = 1;
      this.tabelListParmas.pageSize = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.tabelListParmas.pageNo = current;
      this.getList();
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style scoped lang="less">
.add-btn {
  margin-bottom: 16px;
}
.btn-grounp {
  display: flex;
  span {
    flex: 1;
  }
}
</style>
