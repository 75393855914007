<template>
  <el-dialog
    title="修改责任清单"
    :visible.sync="show"
    width="1024px"
    :close-on-click-modal="false"
    @close="dialogClose"
  >
    <el-table
      :data="responseListData"
      style="width: 100%"
      size="medium"
      border
      v-loading="responseListLoading"
      max-height="485px"
    >
      <el-table-column label="序号" width="120" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="dutyContent" label="责任清单" align="center" width="250">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.dutyContent"
            placeholder="点击输入"
            type="textarea"
            :maxlength="200"
            :autosize="{ minRows: 6 }"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="fulfillContent" label="履职清单" align="center">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.fulfillContent"
            placeholder="点击输入"
            type="textarea"
            :maxlength="500"
            :autosize="{ minRows: 6 }"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="{ row, $index }">
          <span class="danger-btn" @click="deleteConfig(row.id, $index)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="footer-btn-grounp">
      <div class="add-btn" @click="handleConfig">添加配置项</div>
      <div>
        <el-button @click="handelClose">取消</el-button>
        <el-button @click="handleConfirm" type="primary" :loading="btnLoading">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import {
  responseListDetailApi,
  addResponseListApi,
  deleteResponseListApi
} from 'api/super-admin/sys-setting/performance-post';
export default {
  name: 'AddDialog',
  props: {
    postId: {
      type: String,
      required: true,
      default: ''
    },
    dialogResponseVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      responseListLoading: false,
      btnLoading: false,
      show: this.dialogResponseVisible,
      responseListData: []
    };
  },
  methods: {
    //查看责任清单详情接口
    responseListDetailApi() {
      this.responseListLoading = true;
      responseListDetailApi({ posId: this.postId, tenantId: getItem('orgId') }).then(res => {
        if (res.code === 200) {
          setTimeout(() => {
            this.responseListLoading = false;
          }, 500);
          if (res.data.length) {
            res.data.forEach(item => {
              item.dutyId = '';
              item.fulfillId = '';
            });
          }
          this.responseListData = res.data;
        }
      });
    },
    deleteConfig(id, index) {
      //删除
      this.$confirm('请确认是否删除该项责任清单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          this.deleteResponseListApi(id);
        } else {
          this.responseListData.splice(index, 1);
        }
      });
    },
    //删除接口
    deleteResponseListApi(id) {
      //配置项的具体配置列表
      deleteResponseListApi({ id }).then(res => {
        if (res.code === 200) {
          this.btnLoading = false;
          this.$message.success('删除成功');
          this.responseListDetailApi();
        }
      });
    },
    //添加配置项
    handleConfig() {
      //添加项
      let obj = {
        id: '',
        posId: this.postId,
        dutyContent: '',
        fulfillContent: '',
        tenantId: getItem('orgId')
      };
      this.responseListData.push(obj);
    },
    //确定
    handleConfirm() {
      this.addResponseListApi();
    },
    //添加/修改责任-履职详情表（多条）
    addResponseListApi() {
      this.btnLoading = true;
      addResponseListApi(this.responseListData)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$emit('update:dialogResponseVisible', false);
            this.$message.success('操作成功');
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    handelClose() {
      this.$emit('update:dialogResponseVisible', false);
    },
    dialogClose() {
      this.$emit('update:dialogResponseVisible', false);
    }
  },
  watch: {
    dialogResponseVisible(val) {
      this.show = val;
      this.responseListDetailApi();
    }
  }
};
</script>

<style scoped>
.footer-btn-grounp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.add-btn {
  width: 94px;
  height: 32px;
  border: 1px solid #005aab;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #005aab;
  font-size: 14px;
  cursor: pointer;
}
</style>
