import request from '@/utils/request.js';

/**
 * 查询系统岗位配置分页列表
 * @returns { Promise }
 */
export function getList(params) {
  return request({
    url: '/traffic-construction/sysPos/pageConfig',
    method: 'get',
    params
  });
}

/**
 * 新增/修改履职岗位
 * @returns { Promise }
 */
export function addOrEditConfig(data) {
  return request({
    url: '/traffic-construction/sysPos/addOrEditConfig',
    method: 'post',
    data
  });
}

/**
 * 新增/修改履职岗位
 * @returns { Promise }
 */
export function deleteConfig(data) {
  return request({
    url: '/traffic-construction/sysPos/deleteConfig',
    method: 'post',
    data
  });
}

/**
 * 查看岗位-责任-履职详情表
 * @returns { Promise }
 */
export function responseListDetailApi(params) {
  return request({
    url: '/traffic-construction/sysDutyFulfill/detail',
    method: 'get',
    params
  });
}

/**
 * 添加/修改责任-履职详情表（多条）
 * @returns { Promise }
 */
export function addResponseListApi(data) {
  return request({
    url: '/traffic-construction/sysDutyFulfill/addOrEdit',
    method: 'post',
    data
  });
}

/**
 * 删除责任-履职详情表
 * @returns { Promise }
 */
export function deleteResponseListApi(data) {
  return request({
    url: '/traffic-construction/sysDutyFulfill/delete',
    method: 'post',
    data
  });
}
