var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"修改责任清单","visible":_vm.show,"width":"1024px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.dialogClose}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.responseListLoading),expression:"responseListLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.responseListData,"size":"medium","border":"","max-height":"485px"}},[_c('el-table-column',{attrs:{"label":"序号","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"dutyContent","label":"责任清单","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入","type":"textarea","maxlength":200,"autosize":{ minRows: 6 }},model:{value:(row.dutyContent),callback:function ($$v) {_vm.$set(row, "dutyContent", $$v)},expression:"row.dutyContent"}})]}}])}),_c('el-table-column',{attrs:{"prop":"fulfillContent","label":"履职清单","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入","type":"textarea","maxlength":500,"autosize":{ minRows: 6 }},model:{value:(row.fulfillContent),callback:function ($$v) {_vm.$set(row, "fulfillContent", $$v)},expression:"row.fulfillContent"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',{staticClass:"danger-btn",on:{"click":function($event){return _vm.deleteConfig(row.id, $index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"footer-btn-grounp",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"add-btn",on:{"click":_vm.handleConfig}},[_vm._v("添加配置项")]),_c('div',[_c('el-button',{on:{"click":_vm.handelClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.handleConfirm}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }